import React from "react";
import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Image from "../components/image";

const Contact = (props) => (
  <Layout>
    <Seo title="Contact Information" pathname={props.location.pathname} />
    <Container>
      <Wrapper>
        <h1 className="-textCenter">Contact Information</h1>
        <Row gap={60}>
          <Box size={50}>
            <Image
              filename="ABUILD_Office.jpg"
              alt="Abuild Office"
              rounded
              addShadow
            />
          </Box>
          <Box gap={40} size={50}>
            <p>
              <b>Email:</b>{" "}
              <a href="mailto:abuild@abuild.co.nz">abuild@abuild.co.nz</a>
            </p>
            <p>
              <b>Phone:</b> <a href="tel:+64 4 478 3929">+64 4 478 3929</a>
            </p>
            <p>
              <b>Facsimile:</b> <a href="tel:+64 4 478 3424">+64 4 478 3424</a>
            </p>
            <Box>
              <p>
                <b>Abuild™ Consulting Engineers Ltd:</b>
              </p>
              <p>2nd Floor, 21-29 Broderick Rd</p>
              <p>Johnsonville</p>
              <p>Wellington</p>
              <p>New Zealand</p>
            </Box>
            <p>
              <b>Office Opening Hours:</b> 8.30am – 5pm Monday to Friday
            </p>
            <Box>
              <p>
                <b>Postal Address:</b>
              </p>
              <p>P.O. Box 13 273</p>
              <p>Johnsonville</p>
              <p>Wellington 6440</p>
              <p>New Zealand</p>
            </Box>
          </Box>
        </Row>

        {/* <form name="contact" method="POST" data-netlify="true">
          <p>
            <label>
              Name <input type="text" name="name" />
            </label>
          </p>
          <p>
            <label>
              Email <input type="email" name="email" />
            </label>
          </p>
          <p>
            <label>
              Contact Number <input type="phone" name="phone" />
            </label>
          </p>
          <p>
            <label>
              Message <textarea name="message" />
            </label>
          </p>
          <p>
            <button className="primary" type="submit">
              Send Message
            </button>
          </p>
        </form> */}
      </Wrapper>
    </Container>
  </Layout>
);

export default Contact;
